import React, { useEffect } from "react";
import { useState } from "react";
import { Form, InputGroup, Modal } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import { casinoTabs } from "../../lib/data/casinoTabs.data";

const GamesSearchModal = () => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const [filteredData, setFilteredData] = useState(null);
  const [searchText, setSearchText] = useState(false);

  const handleClose = () => {
    setFilteredData(null);
    setShow(false);
  };

  const handleSearch = (e) => {
    if (e) {
      setSearchText(true);

      let filteredValue = Object.values(casinoTabs.homeData)?.filter((item) => {
        if (typeof item !== "string") {
          return item.name.toLowerCase().indexOf(e?.toLowerCase()) > -1;
        }
        return false;
      });
      setFilteredData(filteredValue);
      console.log("Search Data", filteredValue);
    } else {
      setSearchText(false);
    }
  };
  console.log("FilterData", filteredData);

  return (
    <>
      <div className="searchGames" onClick={handleShow}>
        <BiSearch />
        <p>Search Games</p>
      </div>

      <Modal show={show} onHide={handleClose} className="search-modal">
        <Modal.Header closeButton>
          <Modal.Title>Search For Games</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3 search-icon">
            <Form.Control
              placeholder="Search"
              aria-label="Text input with dropdown button"
              type="search"
              onChange={(e) => handleSearch(e.target.value)}
              autoFocus
            />
          </InputGroup>

          <ul className="game-list">
            {filteredData &&
              filteredData.map((item) => (
                <li>
                  <a href={item.href}>
                    <img src={item.imgUrl} />
                    <span>{item.name}</span>
                  </a>
                </li>
              ))}
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GamesSearchModal;
