export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;

export const casinoTabs = {
  homeData: {
    Sports: {
      href: "/exchange/Cricket",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/home-img1.png",
      alt: "sports",
      name: "Sports",
    },
    // Inplay: {
    //   href: "/exchange/Inplay",
    //   code: "",
    //   casino: "",
    //   provider: "",
    //   homeUrl: pageUrlGenerator("/home"),
    //   imgUrl: "./images/casino/home/home-img2.png",
    //   alt: "inplay",
    //   name: "Inplay",
    // },
    VirtualSports: {
      href: "/exchange/Virtual Sports",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/home-img3.png",
      alt: "virtual sports",
      name: "Virtual Sports",
    },

    Evolution: {
      href: "/casino/evolution",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/providers/evolution.jpg",
      alt: "evolution",
      name: "Evolution",
    },
    Ezugi: {
      href: "/casino/ezugi",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/providers/ezugi.jpg",
      alt: "ezugi",
      name: "Ezugi",
    },
    Supernowa: {
      href: "/casino/supernowa",
      code: "",
      casino: "supernowa",
      provider: "Supernowa",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/providers/supernowa.jpg",
      alt: "supernowa",
      name: "Supernowa",
    },
    XPG: {
      href: "/casino/xpg",
      code: "",
      casino: "xpg",
      provider: "XPG",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/providers/xpg.jpg",
      alt: "xpg",
      name: "XPG",
    },
    Vivo: {
      href: "/casino/vivo",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/providers/vivo.jpg",
      alt: "vivo",
      name: "Vivo",
    },
    Qtech: {
      href: "/casino/qtech",
      code: "",
      casino: "qtech",
      provider: "Qtech",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/providers/qtech.jpg",
      alt: "qtech",
      name: "Qtech",
    },
    Onlyplay: {
      href: "/casino/onlyplay",
      code: "",
      casino: "onlyplay",
      provider: "Onlyplay",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/providers/onlyplay.jpg",
      alt: "onlyplay",
      name: "Onlyplay",
    },
    Pragmatic: {
      href: "/casino/pragmatic",
      code: "",
      casino: "pragmatic",
      provider: "Pragmatic",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/providers/pragmatic.jpg",
      alt: "pragmatic",
      name: "Pragmatic",
    },

    Aviator: {
      href: "/casino/spribe/aviator",
      code: "",
      casino: "spribe",
      provider: "Spribe",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/spribe/aviator.jpg",
      alt: "aviator",
      name: "Aviator",
    },
    Dice: {
      href: "/casino/spribe/dice",
      code: "",
      casino: "spribe",
      provider: "Spribe",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/spribe/dice.jpg",
      alt: "dice",
      name: "Dice",
    },
    Goal: {
      href: "/casino/spribe/goal",
      code: "",
      casino: "spribe",
      provider: "Spribe",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/spribe/goal.jpg",
      alt: "goal",
      name: "Goal",
    },
    MiniRoulette: {
      href: "/casino/spribe/miniroulette",
      code: "",
      casino: "spribe",
      provider: "Spribe",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/spribe/mini-roulette.jpg",
      alt: "mini roulette",
      name: "Mini Roulette",
    },

    VIPRoulette: {
      href: "/casino/ezgevo-vip-roulette",
      code: "",
      casino: "evolution",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/evolution/vip-roulette.jpg",
      alt: "vip roulette",
      name: "VIP Roulette",
    },
    FootballStudio: {
      href: "/casino/ezgevo-football-studio",
      code: "",
      casino: "evolution",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/evolution/football-studio.jpg",
      alt: "football studio",
      name: "Football Studio",
    },
    GonzosTreasureHunt: {
      href: "/casino/ezgevo-gonzos-treasure-hunt",
      code: "",
      casino: "evolution",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/evolution/gonzos-treasure-hunt.jpg",
      alt: "gonzos treasure hunt",
      name: "Gonzo's Treasure Hunt",
    },
    InfiniteBlackjack: {
      href: "/casino/ezgevo-infinite-blackjack",
      code: "",
      casino: "evolution",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/evolution/infinite-blackjack.jpg",
      alt: "infinite blackjack",
      name: "Infinite Blackjack",
    },

    BetOnTeenPatti: {
      href: "/casino/ezg-bet-on-teen-patti",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/ezugi/bet-on-teen-patti.jpg",
      alt: "bet on teen patti",
      name: "Bet On Teen Patti",
    },
    Cards32: {
      href: "/casino/ezg-32-cards",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/ezugi/32-cards.jpg",
      alt: "32 cards",
      name: "32 cards",
    },
    SicBo: {
      href: "/casino/ezg-sic-bo",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/ezugi/sic-bo.jpg",
      alt: "sic bo",
      name: "Sic Bo",
    },
    CricketWar: {
      href: "/casino/ezg-cricket-war",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/ezugi/cricket-war.jpg",
      alt: "cricket war",
      name: "Cricket War",
    },
    Lucky7: {
      href: "/casino/ezg-lucky-7",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/ezugi/lucky-7.jpg",
      alt: "lucky 7",
      name: "Lucky 7",
    },

    AkbarRomeoWalter: {
      href: "/casino/sn-akbar-romeo-walter",
      code: "",
      casino: "supernowa",
      provider: "WorldCasino",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/supernowa/akbar-romeo-walter.jpg",
      alt: "akbar romeo walter",
      name: "Akbar Romeo Walter",
    },
    ClassicAndarBahar: {
      href: "/casino/sn-classic-andar-bahar",
      code: "",
      casino: "supernowa",
      provider: "WorldCasino",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/supernowa/classic-andar-bahar.jpg",
      alt: "classic andar bahar",
      name: "Classic Andar Bahar",
    },
    RNG3PlayerTeenPatti: {
      href: "/casino/sn-rng-3-player-teen-patti",
      code: "",
      casino: "supernowa",
      provider: "WorldCasino",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/supernowa/rng-3-player-teen-patti.jpg",
      alt: "rng 3 player teen patti",
      name: "RNG 3 Player Teen Patti",
    },
    RNGCasinoQueen: {
      href: "/casino/sn-rng-casino-queen",
      code: "",
      casino: "supernowa",
      provider: "WorldCasino",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/supernowa/rng-casino-queen.jpg",
      alt: "rng casino queen",
      name: "RNG Casino Queen",
    },

    DragonRoulette: {
      href: "/casino/xpg-dragon-roulette",
      code: "",
      casino: "Xprogramming",
      provider: "WorldCasino",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/xpg/dragon-roulette.jpg",
      alt: "dragon roulette",
      name: "Dragon Roulette",
    },
    FastBaccarat: {
      href: "/casino/xpg-fast-baccarat",
      code: "",
      casino: "Xprogramming",
      provider: "WorldCasino",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/xpg/fast-baccarat.jpg",
      alt: "fast baccarat",
      name: "Fast Baccarat",
    },
    VIPGoldBaccarat: {
      href: "/casino/xpg-vip-gold-baccarat",
      code: "",
      casino: "Xprogramming",
      provider: "WorldCasino",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/xpg/vip-gold-baccarat.jpg",
      alt: "vip gold baccarat",
      name: "VIP Gold Baccarat",
    },

    AmericanAutoRoulette: {
      href: "/casino/vivo-american-auto-roulette",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/vivo/american-auto-roulette.jpg",
      alt: "american auto roulette",
      name: "American Auto Roulette",
    },
    BulgariaBlackjack: {
      href: "/casino/vivo-bulgaria-blackjack",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/vivo/bulgaria-blackjack.jpg",
      alt: "bulgaria blackjack",
      name: "Bulgaria Blackjack",
    },
    DragonTiger: {
      href: "/casino/vivo-dragon-tiger",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/vivo/dragon-tiger.jpg",
      alt: "dragon tiger",
      name: "Dragon Tiger",
    },
    TeenPatti: {
      href: "/casino/vivo-teen-patti",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/vivo/teen-patti.jpg",
      alt: "teen patti",
      name: "Teen Patti",
    },

    BetOnPoker: {
      href: "/casino/qt-bet-on-poker",
      code: "",
      casino: "Qtech",
      provider: "WorldCasino",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/qtech/bet-on-poker.jpg",
      alt: "bet on poker",
      name: "Bet On Poker",
    },
    DiceDuel: {
      href: "/casino/qt-dice-duel",
      code: "",
      casino: "Qtech",
      provider: "WorldCasino",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/qtech/dice-duel.jpg",
      alt: "dice duel",
      name: "Dice Duel",
    },
    Baccarat: {
      href: "/casino/qt-baccarat",
      code: "",
      casino: "Qtech",
      provider: "WorldCasino",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/qtech/baccarat.jpg",
      alt: "baccarat",
      name: "Baccarat",
    },
    Poker6: {
      href: "/casino/qt-6-poker",
      code: "",
      casino: "Qtech",
      provider: "WorldCasino",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/qtech/6-poker.jpg",
      alt: "6 poker",
      name: "6 Poker",
    },

    HotAndSpicyMegaways: {
      href: "/casino/qtechonlyplay-hot-and-spicy-megaways",
      code: "",
      casino: "Onlyplays",
      provider: "QTECH",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/onlyplay/hot-and-spicy-megaways.jpg",
      alt: "hot and spicy megaways",
      name: "Hot And Spicy Megaways",
    },
    CricketCrash: {
      href: "/casino/gt-cricket-crash",
      code: "",
      casino: "Onlyplays",
      provider: "QTECH",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/onlyplay/cricket-crash.jpg",
      alt: "cricket crash",
      name: "Cricket Crash",
    },
    LuckyOcean: {
      href: "/casino/qtechonlyplay-lucky-ocean",
      code: "",
      casino: "Onlyplays",
      provider: "QTECH",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/onlyplay/lucky-ocean.jpg",
      alt: "lucky ocean",
      name: "Lucky Ocean",
    },
    NeedForX: {
      href: "/casino/qtechonlyplay-need-for-x",
      code: "",
      casino: "Onlyplays",
      provider: "QTECH",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/home/onlyplay/need-for-x.jpg",
      alt: "need for x",
      name: "Need For X",
    },
  },

  // spribe: {
  //   href: "/casino/sprie",
  //   code: "",
  //   provider: "spribe",
  //   homeUrl: "",
  //   games: {
  //     mines: {
  //       href: "/casino/spribe/mines",
  //       code: "mines",
  //       casino: "spribe",
  //       provider: "mines",
  //       homeUrl: "",
  //       cover: { src: "./images/spribe/02.jpg", alt: "" },
  //     },
  //     goal: {
  //       href: "/casino/spribe/goal",
  //       code: "goal",
  //       casino: "spribe",
  //       provider: "goal",
  //       homeUrl: "",
  //       cover: { src: "./images/spribe/06.png", alt: "" },
  //     },
  //     dice: {
  //       href: "/casino/spribe/dice",
  //       code: "dice",
  //       casino: "spribe",
  //       provider: "dice",
  //       homeUrl: "",
  //       cover: { src: "./images/spribe/07.jpg", alt: "" },
  //     },
  //     aviator: {
  //       href: "/casino/spribe/aviator",
  //       code: "aviator",
  //       casino: "spribe",
  //       provider: "aviator",
  //       homeUrl: "",
  //       cover: { src: "./images/spribe/03.png", alt: "" },
  //     },
  //     pilnko: {
  //       href: "/casino/spribe/pilnko",
  //       code: "pilnko",
  //       casino: "spribe",
  //       provider: "pilnko",
  //       homeUrl: "",
  //       cover: { src: "./images/spribe/08.jpg", alt: "" },
  //     },
  //     miniroulette: {
  //       href: "/casino/spribe/miniroulette",
  //       code: "miniroulette",
  //       casino: "spribe",
  //       provider: "miniroulette",
  //       homeUrl: "",
  //       cover: { src: "./images/spribe/04.png", alt: "" },
  //     },
  //     hilo: {
  //       href: "/casino/spribe/hilo",
  //       code: "hilo",
  //       casino: "miniroulette",
  //       provider: "hilo",
  //       homeUrl: "",
  //       cover: { src: "./images/spribe/05.png", alt: "" },
  //     },
  //   },
  // },
};
