import { EvolutionGames } from "./evolutiongames.data";
import { EzugiGames } from "./ezugigames.data";
import { WordCasinoGames } from "./wcogames.data";

export const CasinoProviders = {
  evolution: {
    code: "",
    casino: "ezugi",
    href: "/casino/evolution",
    provider: "EVO_LOBBY",
    homeUrl: "",
    games: EvolutionGames,
  },
  ezugi: {
    href: "/casino/ezugi",
    code: "",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    games: EzugiGames,
  },
  supernowa: {
    href: "/casino/supernowa",
    code: "",
    casino: "wco",
    provider: "SN",
    homeUrl: "",
    games: {},
  },
  worldcasino: {
    href: "/casino/worldcasino",
    code: "",
    casino: "wco",
    provider: "",
    homeUrl: "",
    games: {},
  },
  xpg: {
    href: "/casino/xpg",
    code: "",
    casino: "wco",
    provider: "XPG",
    homeUrl: "",
    games: {},
  },
  onetouch: {
    href: "/casino/onetouch",
    code: "",
    casino: "wco",
    provider: "OT",
    homeUrl: "",
    games: {},
  },
  onlyplay: {
    href: "/casino/onlyplay",
    code: "",
    casino: "wco",
    provider: "GT",
    homeUrl: "",
    games: {},
  },
  pragmaticplay: {
    href: "/casino/pragmaticplay",
    code: "",
    casino: "wco",
    provider: "PP",
    homeUrl: "",
    games: {},
  },
  qtech: {
    href: "/casino/qtech",
    code: "",
    casino: "qtech",
    provider: "qtech",
    homeUrl: "",
    games: {},
  },
  vivo: {
    href: "/casino/vivo",
    code: "vivo",
    casino: "vivo",
    provider: "",
    homeUrl: "",
  },

  spribe: {
    href: "/casino/spribe",
    code: "",
    provider: "spribe",
    homeUrl: "",
    games: {
      mines: {
        href: "/casino/spribe/mines",
        code: "mines",
        casino: "spribe",
        provider: "mines",
        homeUrl: "",
        cover: { src: "./images/spribe/02.jpg", alt: "" },
      },
      goal: {
        href: "/casino/spribe/goal",
        code: "goal",
        casino: "spribe",
        provider: "goal",
        homeUrl: "",
        cover: { src: "./images/spribe/06.png", alt: "" },
      },
      dice: {
        href: "/casino/spribe/dice",
        code: "dice",
        casino: "spribe",
        provider: "dice",
        homeUrl: "",
        cover: { src: "./images/spribe/07.jpg", alt: "" },
      },
      aviator: {
        href: "/casino/spribe/aviator",
        code: "aviator",
        casino: "spribe",
        provider: "aviator",
        homeUrl: "",
        cover: { src: "./images/spribe/03.png", alt: "" },
      },
      plinko: {
        href: "/casino/spribe/plinko",
        code: "plinko",
        casino: "spribe",
        provider: "plinko",
        homeUrl: "",
        cover: { src: "./images/spribe/08.jpg", alt: "" },
      },
      miniroulette: {
        href: "/casino/spribe/miniroulette",
        code: "mini-roulette",
        casino: "spribe",
        provider: "mini-roulette",
        homeUrl: "",
        cover: { src: "./images/spribe/04.png", alt: "" },
      },
      hilo: {
        href: "/casino/spribe/hilo",
        code: "hi-lo",
        casino: "spribe",
        provider: "hi-lo",
        homeUrl: "",
        cover: { src: "./images/spribe/05.png", alt: "" },
      },
    },
  },
  Slots: {
    twinspin: {
      href: "/casino/ezugi/twinspin",
      code: "1100039",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/twin-and-spin.jpg", alt: "" },
    },
    gonzoquest: {
      href: "/casino/ezugi/gonzoquest",
      code: "1100018",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/gonzo-treasure-hunt.jpg", alt: "" },
    },

    asgardianstones: {
      href: "/casino/ezugi/asgardianstones",
      code: "1100096",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/asgardianstones1.jpg", alt: "" },
    },
    secrets: {
      href: "/casino/ezugi/secrets",
      code: "1100106",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/secrets.jpg", alt: "" },
    },
    grandwheel: {
      href: "/casino/ezugi/grandwheel",
      code: "1100150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/Grand-wheel.jpg", alt: "" },
    },
    Strike777: {
      href: "/casino/ezugi/Strike777",
      code: "1100031",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Nights1000: {
      href: "/casino/ezugi/Nights1000",
      code: "1100003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CashUltimate: {
      href: "/casino/ezugi/CashUltimate",
      code: "1100070",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CashVolt: {
      href: "/casino/ezugi/CashVolt",
      code: "1100071",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DazzleMegaways: {
      href: "/casino/ezugi/DazzleMegaways",
      code: "1100083",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DivinieFortuneMegaways: {
      href: "/casino/ezugi/DivinieFortuneMegaways",
      code: "1100089",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Dynamiteriches: {
      href: "/casino/ezugi/Dynamiteriches",
      code: "1100073",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FortuneHouse: {
      href: "/casino/ezugi/FortuneHouse",
      code: "1100007",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FruitShopMegaways: {
      href: "/casino/ezugi/FruitShopMegaways",
      code: "1100094",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenCryptex: {
      href: "/casino/ezugi/GoldenCryptex",
      code: "1100147",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenTsar: {
      href: "/casino/ezugi/GoldenTsar",
      code: "1100149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GorillaKingdom: {
      href: "/casino/ezugi/GorillaKingdom",
      code: "1100088",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MystryReels: {
      href: "/casino/ezugi/MystryReels",
      code: "1100061",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RageoftheSea: {
      href: "/casino/ezugi/RageoftheSea",
      code: "1100116",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ReelKingMegaways: {
      href: "/casino/ezugi/ReelKingMegaways",
      code: "1100052",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TurnFortune: {
      href: "/casino/ezugi/TurnFortune",
      code: "1100041",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TwinSpinMegaways: {
      href: "/casino/ezugi/TwinSpinMegaways",
      code: "1100039",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WillysChills: {
      href: "/casino/ezugi/WillysChills",
      code: "1100128",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    VikingsSlots: {
      href: "/casino/ezugi/VikingsSlots",
      code: "1100124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
  },
};
