import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import i18next, { t } from "i18next";

import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Nav, Navbar } from "react-bootstrap";
// import GamesSearchModal from "../views/components/GamesSearchModal";

import logo from "../assets/images/logo.png";
import UserIcon from "../assets/images/user-icon.svg";
import LoginIcon from "../assets/images/login-icon.svg";
import LoginIcon2 from "../assets/images/login-icon2.svg";
import LiveIcon from "../assets/images/live-icon.svg";
import GamesSearchModal from "../views/components/GamesSearchModal";

const Header = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  const handleLanguageChange = (value) => {
    i18next.changeLanguage(value.target.value);
    window.location.reload(false);
  };

  return (
    <header className="header beforeheader">
      <div className="top-header">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className="logo">
                    <a href="/">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                    </a>
                  </div>

                  <GamesSearchModal />

                  {/* <div className="searchbar d-none d-xl-block">
                    <img src={SearchIcon} alt="search" />
                    <Form.Control type="text" placeholder="Search Events" />
                    <Button
                      type="submit"
                      className="search-clear"
                      id="searchClear"
                      style={{ display: "none" }}
                    >
                      <img src={SearchClearIcon} alt="search clear" />
                    </Button>
                  </div> */}

                  {/* <div>
                    <GamesSearchModal />
                  </div> */}
                </div>
                <div className="headerRight">
                  <Form>
                    {/* <div className="d-none d-md-flex align-items-center">
                      <Form.Group
                        className="form-group user"
                        controlId="loginUserName"
                      >
                        <img src={UserIcon} alt="user" />
                        <Form.Control type="text" placeholder="Username" />
                      </Form.Group>
                      <Form.Group
                        className="form-group"
                        controlId="loginPassword"
                      >
                        <Form.Control type="password" placeholder="Password" />
                      </Form.Group>
                      <Form.Group
                        className="form-group"
                        controlId="loginValidation"
                      >
                        <Form.Control
                          type="password"
                          placeholder="Validation"
                        />
                        <div className="validation">8705</div>
                      </Form.Group>
                    </div> */}
                    <div className="btns d-flex align-items-center">
                      <Form.Group className="form-group">
                        <Button
                          variant="primary"
                          onClick={() => {
                            navigate("/sign-in");
                          }}
                          className="button-primary ms-1 login-bnt"
                        >
                          <img
                            src={LoginIcon2}
                            alt="login"
                            className="d-md-none"
                          />
                          Login
                          <img
                            src={LoginIcon}
                            alt="login"
                            className="d_sm_none"
                          />
                        </Button>
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Button
                          variant="primary"
                          onClick={() => {
                            navigate("/mobile-number");
                          }}
                          className="button-primary signup"
                        >
                          Sign up
                        </Button>
                      </Form.Group>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bottom_head d-none d-lg-flex justify-content-between align-items-center">
        <ul>
          <li>
            <a href="/home" className="active">
              <span>Home</span>
            </a>
          </li>
          <li>
            <a href="/exchange/Inplay">
              <span>In-Play</span>
            </a>
          </li>
          <li>
            <a href="/exchange/Cricket">
              <span>Cricket</span>
              {/* <div className="live-match">
                <img src={LiveIcon} alt="live-icon" />
                <span>2</span>
              </div> */}
            </a>
          </li>
          <li>
            <a href="/exchange/Soccer">
              <span>Soccer</span>
            </a>
          </li>
          <li>
            <a href="/exchange/Tennis">
              <span>Tennis</span>
            </a>
          </li>
          <li>
            <a href="/exchange/Virtual Cricket">
              <span>Virtual Cricket</span>
            </a>
          </li>
          <li>
            <a href="/exchange/Horse Racing">
              <span>Horse Racing</span>
            </a>
          </li>
          <li>
            <a href="/exchange/Snooker">
              <span>Snooker</span>
            </a>
          </li>
          <li>
            <a href="/exchange/Greyhound Racing">
              <span>Greyhound Racing</span>
            </a>
          </li>
          <li className="dark_menu-li">
            <a href="/casino/ezugi">
              <span>Casino</span>
            </a>
          </li>
          <li>
            <a href="/exchange/Politics">
              <span>Politics</span>
            </a>
          </li>
        </ul>

        <div className="time-zone d-none d-xl-block">
          <span>Time Zone : </span>
          <strong>GMT+5:30</strong>
        </div>
      </div>

      <div className="bot-header d-none">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div className="middle-part">
                <Navbar expand="lg">
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                      <Nav.Link className="active" href="/home">
                        Home
                      </Nav.Link>
                      <Nav.Link href="javascript:void(0)">In-Play</Nav.Link>
                      <Nav.Link href="javascript:void(0)">
                        Multi Markets
                      </Nav.Link>
                      <Nav.Link href="/sportsbook">
                        Cricket
                        {/* <div className="live-match">
                          <img src={LiveIcon} alt="live-icon" />
                          <span>2</span>
                        </div> */}
                      </Nav.Link>
                      <Nav.Link href="javascript:void(0)">
                        Soccer
                        {/* <div className="live-match">
                          <img src={LiveIcon} alt="live-icon" />
                          <span>25</span>
                        </div> */}
                      </Nav.Link>
                      <Nav.Link href="javascript:void(0)">
                        Tennis
                        {/* <div className="live-match">
                          <img src={LiveIcon} alt="live-icon" />
                          <span>9</span>
                        </div> */}
                      </Nav.Link>
                      <Nav.Link href="javascript:void(0)">
                        Virtual Cricket
                      </Nav.Link>
                      <Nav.Link href="javascript:void(0)">
                        E-Soccer
                        {/* <div className="live-match">
                          <img src={LiveIcon} alt="live-icon" />
                          <span>2</span>
                        </div> */}
                      </Nav.Link>
                      <Nav.Link href="javascript:void(0)">IPL Winner</Nav.Link>
                      <Nav.Link href="javascript:void(0)">Binary</Nav.Link>
                      <Nav.Link href="javascript:void(0)">Sky Trader</Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </header>
  );
};

export default Header;
